import React from "react";
import { Header } from "./Header";
import { MainCard } from "./MainCard";
import one from "../assets/img/one.png";
import two from "../assets/img/two.png";
import three from "../assets/img/three.png";
import five from "../assets/img/five.png";

export const Main = () => {
  return (
    <main
      id="vote"
      className="bg-main  bg-cover bg-center"
      style={{ backgroundImage: `url(/img/bg-main.png)` }}
    >
      <Header />
      <div className="max-w-[1330px] w-[90%] mx-auto flex flex-col items-center pt-[170px]">
        <span
          className="text-[60px] sm:!text-[15px]  text-[#fff]  gap-2 uppercase h-[150px] w-[400px] flex items-center justify-center px-3 rounded-tl-[16px] rounded-[16px] sm:w-[300px] font-bold"
          style={{
            background: `linear-gradient(to right,#D33448,#EE4D43)`,
          }}
        >
          Laying Cat
        </span>
        {/* <h1 className=" text-[#fff] text-[80px]  uppercase BigShouldersDisplayBold text-center lg:text-[80px] sm:!text-[50px] ">
          This NFT is rarer than finding a needle in a haystack{" "}
          <span
            style={{
              background: `linear-gradient(to right,#D33448,#EE4D43)`,
            }}
            className="px-4"
          >
            HAHAHAHAHA
          </span>
        </h1> */}

        {/* <div className="mt-8 w-full lg:flex-col flex items-center gap-12 sm:mt-3">
          <p className="text-[#CFC7CD] text-[22px] font-normal lg:w-full lg:text-center w-[776px] mx-auto text-center">
            Welcome to FELIXMALMBER1, Please vote for the most{" "}
            <span
              className="text-[#fff] px-1 py-1"
              style={{
                background: `linear-gradient(to right,#D33448,#EE4D43)`,
              }}
            >
              boring
            </span>{" "}
            and{" "}
            <span
              className="text-[#fff] px-1 py-1"
              style={{
                background: `linear-gradient(to right,#D33448,#EE4D43)`,
              }}
            >
              dumb
            </span>{" "}
            president hahahahahaha
          </p>
        </div> */}
      </div>
      <div className="justify-center mt-10 gap-[33px] grid-cols-3 flex mx-auto lg:grid-cols-1 lg:gap-[20px] sm:mt-[40px] sm:!gap-[10px]">
        {/* <MainCard img={one} title="First President" votes="101" /> */}
        <MainCard img={five} title="Second President" votes="310" />
        {/* <MainCard img={three} title="Third President" votes="91" /> */}
      </div>
    </main>
  );
};
