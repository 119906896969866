"use client";
import React, { useState } from "react";

export const Header = () => {
  const [active, setActive] = useState(false);
  return (
    <header className="flex items-center justify-between w-[90%] max-w-[1528px] mx-auto absolute left-1/2 -translate-x-1/2 top-10 lg:z-10">
      <h1 className=" text-[#fff] text-[50px]  uppercase BigShouldersDisplayBold text-center lg:text-[80px] sm:!text-[30px] ">
        Laying Cat
      </h1>
      <div className="flex items-center justify-between gap-10">
        <nav
          className={`flex items-center gap-[30px] lg:fixed lg:flex-col lg:h-[100vh] lg:w-[200px] lg:bg-[#160414] transition-all lg:-top-10 lg:-left-[106%] lg:pt-10 lg:z-50 `}
          style={active == true ? { left: "-6%" } : {}}
        >
          <a
            href="#vote"
            className="text-[#A09DA2] text-[16px] font-medium lg:text-[#fff]"
          >
            Home
          </a>
          <a
            href="#details"
            className="text-[#A09DA2] text-[16px] font-medium  lg:text-[#fff]"
          >
            MEMES
          </a>

          <a
            href="#social"
            className="text-[#F0EFF0] font-semibold text-[16px]  bg-[#D33448] h-[56px] w-[169px]  items-center justify-center rounded-lg   sm:text-[14px] sm:w-[120px] sm:h-[40px] hidden lg:flex"
          >
            SOCIAL LINKS
          </a>
        </nav>
        <a
          href="#social"
          className="text-[#F0EFF0] font-semibold text-[16px]  bg-[#D33448] h-[56px] w-[169px] flex items-center justify-center rounded-lg   sm:text-[14px] sm:w-[120px] sm:h-[40px] lg:hidden"
        >
          SOCIAL LINKS
        </a>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="#fff"
        className="w-6 h-6 hidden lg:block"
        onClick={(e) => {
          console.log("workking");
          setActive(!active);
        }}
      >
        <path
          fillRule="evenodd"
          d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z"
          clipRule="evenodd"
        />
      </svg>
    </header>
  );
};
