import React, { useState } from "react";
import tick from "../assets/img/tick.svg";
export const MainCard = ({ title, img, votes }) => {
  const [active, setactive] = useState(0);
  return (
    <div
      className={`p-[10px] h-[550px]  border-[1px] border-[transparent] mb-[115px] lg:mb-[20px] sm:!mb-[10px]  sm:h-[350px]  rounded-[16px]  hover:border-[1px] hover:border-[#fff] sm:p-[10px]`}
    >
      <div className="rounded-[16px] h-full relative">
        <img
          src={img}
          alt=""
          className="w-full h-full object-cover rounded-[16px]"
        />
        {/* <div className="rounded-[16px]  px-[26px] h-[131px] glass w-full absolute bottom-0 left-0 flex flex-col justify-center items-center">
          <div className="flex items-center justify-between mb-3 w-full">
            <h1 className="flex items-center gap-1 text-[20px] big  font-medium text-[#fff]">
              {title}
            </h1>
            <span className="jura font-bold text-[#fff] text-[15px] px-[12px] py-[12px] bg-[#B00BEE] rounded-lg">
              {Number(votes) + active} Votes
            </span>
          </div> */}

          {/* <div className="flex items-center justify-between w-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill={`${active == 1 ? "#F4C511" : "#fff"}`}
              className={`w-[30px] cursor-pointer ${
                active == 1 && "scale-125"
              }`}
              onClick={(e) => {
                setactive(active == 0 ? 1 : 0);
              }}
            >
              <path d="M7.493 18.5c-.425 0-.82-.236-.975-.632A7.48 7.48 0 0 1 6 15.125c0-1.75.599-3.358 1.602-4.634.151-.192.373-.309.6-.397.473-.183.89-.514 1.212-.924a9.042 9.042 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75A.75.75 0 0 1 15 2a2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H14.23c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23h-.777ZM2.331 10.727a11.969 11.969 0 0 0-.831 4.398 12 12 0 0 0 .52 3.507C2.28 19.482 3.105 20 3.994 20H4.9c.445 0 .72-.498.523-.898a8.963 8.963 0 0 1-.924-3.977c0-1.708.476-3.305 1.302-4.666.245-.403-.028-.959-.5-.959H4.25c-.832 0-1.612.453-1.918 1.227Z" />
            </svg>
            <p className="jura font-bold text-[#fff] text-[18px]">
              Vote for the new President
            </p>
          </div> */}
        {/* </div> */}
      </div>
    </div>
  );
};
