import { Digital } from "./components/Digital";
import { DigitalCard } from "./components/DigitalCard";
import { Dive } from "./components/Dive";
import { Exp } from "./components/Exp";
import { Footer } from "./components/Footer";
import { Invest } from "./components/Invest";
import { Join } from "./components/Join";
import { Main } from "./components/Main";
import { ScrollArea } from "./components/ScrollArea";

import "./App.css";
import { Game } from "./components/Game";
function App() {
  return (
    <div className="overflow-hidden">
      <Main />

      <Join />
      <Game />

      <Footer />
    </div>
  );
}

export default App;
