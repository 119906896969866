import React from "react";

export const Game = () => {
  return (
    <div className="relative overflow-hidden mb-40" id="details">
      <span className="w-[380px] h-[380px] bg-[#9e2f2d] rounded-full blur-[400px] block absolute left-1/2 -translate-x-1/2 -top-[350px] lg:w-[200px] lg:h-[200px] lg:top-[0px] lg:blur-[100px] sm:!w-[100px] sm:!h-[100px]"></span>
      <div className=" sm:pb-[0px] w-[90%] py-[85px] max-w-[1528px] mx-auto relative overflow-hidden">
        <h1 className="BigShouldersDisplayBold text-[67px] sm:!text-[40px]   lg:text-[50px] text-[#fff] text-center relative z-10">
          LAYING CAT <br className="sm:hidden" />  {" "}
          <span
            className="text-[#fff] px-1 py-1"
            style={{
              background: `linear-gradient(to right,#D33448,#EE4D43)`,
            }}
          >
            GAME
          </span>{" "}
        </h1>
      </div>
      <div className="flex flex-col gap-10 mt-10">
        <div className="relative">
          <div className="w-[90%] max-w-[1528px] mx-auto grid grid-cols-[1fr_1fr] gap-10 items-center 1lg:grid-cols-1 relative z-10">
            <div className="1lg:order-2 flex flex-col items-start">
              <span
                className="text-[20px] sm:!text-[15px]  text-[#fff]  gap-2 uppercase h-[50px] flex items-center justify-center px-3 rounded-tl-[16px] rounded-[16px]  font-bold"
                style={{
                  background: `linear-gradient(to right,#D33448,#EE4D43)`,
                }}
              >
                LAYCAT
              </span>
              <h1 className="BigShouldersDisplayBold text-[67px] sm:!text-[40px]   lg:text-[50px] text-[#fff] ">
                Laying Cat Attack
              </h1>
              <p className="text-[22px] sm:text-[16px] text-[#A09DA2]  mt-6 mb-[20px]">
                Survive the LAYCAT, and get the highest score!
              </p>
            </div>

            <div>
              <iframe
                src={`${process.env.PUBLIC_URL}/game/index.html`}
                style={{ height: "640px", width: "100%", border: "none" }}
                title="Embedded Index"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
