import React from "react";
import { JoinCard } from "./JoinCard";

export const Invest = ({ order, title, description, img, reverse = null }) => {
  return (
    <div className="relative">
      <div className="w-[90%] max-w-[1528px] mx-auto grid grid-cols-[1fr_1fr] gap-10 items-center 1lg:grid-cols-1 relative z-10">
        {reverse && (
          <img src={img} className="w-full 1lg:order-1 rounded-lg" alt="" />
        )}
        <div className="1lg:order-2 flex flex-col items-start">
          <span
            className="text-[20px] sm:!text-[15px]  text-[#fff]  gap-2 uppercase h-[50px] flex items-center justify-center px-3 rounded-tl-[16px] rounded-[16px]  font-bold"
            style={{
              background: `linear-gradient(to right,#D33448,#EE4D43)`,
            }}
          >
            Meme 
          </span>
          <h1 className="BigShouldersDisplayBold text-[67px] sm:!text-[40px]   lg:text-[50px] text-[#fff] ">
            {title}
          </h1>
          <p className="text-[22px] sm:text-[16px] text-[#A09DA2]  mt-6 mb-[20px]">
            {description}
          </p>
        </div>
        {!reverse && (
          <img src={img} className="w-full 1lg:order-1 rounded-lg" alt="" />
        )}
      </div>
    </div>
  );
};
