import React from "react";
import { JoinCard } from "./JoinCard";
import { Invest } from "./Invest";
import nft1 from "../assets/img/one.png";
import nft2 from "../assets/img/two.png";
import nft3 from "../assets/img/three.png";
export const Join = () => {
  return (
    <div className="relative overflow-hidden mb-40" id="details">
      <span className="w-[380px] h-[380px] bg-[#9e2f2d] rounded-full blur-[400px] block absolute left-1/2 -translate-x-1/2 -top-[350px] lg:w-[200px] lg:h-[200px] lg:top-[0px] lg:blur-[100px] sm:!w-[100px] sm:!h-[100px]"></span>
      <div className=" sm:pb-[0px] w-[90%] py-[85px] max-w-[1528px] mx-auto relative overflow-hidden">
        <h1 className="BigShouldersDisplayBold text-[67px] sm:!text-[40px]   lg:text-[50px] text-[#fff] text-center relative z-10">
          Memes about <br className="sm:hidden" />{" "}
          <span
            className="text-[#fff] px-1 py-1"
            style={{
              background: `linear-gradient(to right,#D33448,#EE4D43)`,
            }}
          >
            Laying
          </span>{" "}
          Cat{" "}
        </h1>
        {/* <p className="text-[16px] text-[#A09DA2] text-center mt-6 ">
          Just another day, another NFT. But hey, it could be a hidden gem!
        </p> */}
      </div>
      <div className="flex flex-col gap-10">
        <Invest
          order={1}
          title="Layin with da President "
          // description="Get ready for a collection of presidential humor that's more entertaining than a campaign rally. From Washington to Lincoln, these NFTs are packed with puns and shenanigans that will make your history teacher proud!"
          img={nft1}
        />

        <Invest
          order={2}
          title="Queen of the White House"
          // description="Who said politics has to be serious? Dive into a world where the Oval Office meets stand-up comedy. These NFTs bring you the lighter side of presidential life, one laugh at a time."
          img={nft2}
          reverse
        />
        <Invest
          order={3}
          title="Hail to the DADDY"
          // description="Forget Hail to the Chief; it's time to hail to the Meme Chief! This collection of NFTs features your favorite presidents in hilarious scenarios that'll have you laughing through history."
          img={nft3}
        />
      </div>
    </div>
  );
};
