import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import TelegramIcon from "@mui/icons-material/Telegram";
import cat from "../assets/img/four.png";
import pumpImg from "../assets/img/pumpfa.png";
export const Footer = () => {
  return (
    <div className=" relative overflow-hidden" id="social">
      <span className="w-[480px] h-[480px] bg-[#9e2f2d] rounded-full blur-[400px] block absolute left-1/2 -translate-x-1/2 -top-[350px]"></span>
      <div className="w-[90%] max-w-[1528px] mx-auto 1lg:flex-col 1lg:items-start flex items-center  1lg:gap-8 h-[120vh] justify-start relative">
        <div className="flex flex-col items-start gap-5">
          <h1 className="text-[70px] BigShouldersDisplayBold font-medium text-[#F0EFF0]">
            JOIN OUR SOCIAL COMMUNITY
          </h1>
          <div className="flex items-center gap-5">
            <a
              href="https://x.com/layingcat"
              className="w-[50px] h-[50px] bg-[#fff] flex items-center justify-center"
              target="_blank" rel="noopener noreferrer"
            >
              <XIcon fontSize="large" />
            </a>
            <a
              href="https://t.me/+s6RZI_4oXBJiNmE0"
              className="w-[50px] h-[50px] bg-[#fff] flex items-center justify-center"
              target="_blank" rel="noopener noreferrer"
            >
              <TelegramIcon fontSize="large" />
            </a>
            <a
              href="https://pump.fun/board"
              className="w-[50px] h-[50px] bg-[#fff] flex items-center justify-center"
              target="_blank" rel="noopener noreferrer"
            >
              <img src={pumpImg} />
            </a>
          </div>
        </div>
        <img
          src={cat}
          alt=""
          className="absolute right-20 zoomeffect w-[450px] "
        />
      </div>
    </div>
  );
};
